import { useThemisAnalytics } from '../hooks/useThemisAnalytics';

const viewBeginUpgradeGtmEvent = () => {
  // trigger GA4 recommended event for view begin upgrade
  useThemisAnalytics().triggerEvent('view_begin_upgrade');
};

const beginUpgradeGtmEvent = () => {
  // trigger GA4 recommended begin upgrade event
  useThemisAnalytics().triggerEvent('begin_upgrade');
};

const viewUpgradeConfirmationGtmEvent = () => {
  // trigger GA4 recommended view upgrade confirmation event
  useThemisAnalytics().triggerEvent('view_upgrade_confirmation');
};

export default {
  viewBeginUpgradeGtmEvent,
  beginUpgradeGtmEvent,
  viewUpgradeConfirmationGtmEvent
};
