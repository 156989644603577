import { CAProvTerr, USStates } from '@legalshield/commerce-cart-library';
import axios from 'axios';
import { Offers, PPLSIPayload, Subscriptions } from '@legalshield/frontend-commons';
import { useQuery } from '@tanstack/react-query';
import Cookies from 'js-cookie';

import { useGetSubscriptionsByIdentityId } from '../hooks/useSubscriptions';
import { useGetProfile } from '../../../hooks/useProfile';

export type Offer = Omit<Offers.Offer, 'ownerId' | 'availableLocalities'> & {
  availableEndDate: string | null;
  availableLocalities: {
    abbreviation: string;
    name?: string;
    requiresLicense?: boolean;
    serviceArea?: string;
  }[];
  availableStartDate: string | null;
  brand: string;
};

export const getRealmDomain = () => (window as unknown as { pplsi: PPLSIPayload }).pplsi?.realmDomain || '';

export const isUpgradeSuccessful = (userId: string) => {
  return Cookies.get(`upgradeSuccessful-${userId}`) === 'true';
};

const getOffers = async (locale: string, region: CAProvTerr | USStates) => {
  const language = locale.split('-')[0];
  const country = region.split('-')[1];

  const params: Record<string, string> = {
    canBeSold: 'true',
    channel: 'NETWORK',
    country,
    language,
    region,
  };

  const response = await axios<Offer[]>({
    baseURL: `https://atlas-queries.api.${getRealmDomain()}`,
    method: 'GET',
    params,
    url: `/v1/public/queries/offers/find`,
  });

  const offers = response.data || [];

  return offers.filter((offer) => {
    if (![Offers.Status.ENROLLABLE, Offers.Status.AVAILABLE_FOR_SALE].includes(offer.status!)) {
      return false;
    }

    if (offer.type === Offers.OfferType.LEGACY) {
      return false;
    }

    return true;
  });
};

export type AvailableMerchandizedTier = {
  availableForSale: boolean;
  merchandizedProduct: Offers.MerchandizedProduct;
  offer: Offer;
  price: Offers.Price;
  tier: string;
};

export type UpgradeableSubscription = {
  availableMerchandizedTiers: AvailableMerchandizedTier[];
  subscription: Subscriptions.SubscriptionResource;
};

export const PRODUCT_TYPE = {
  AssociateAgreement: 'Associate Agreement',
  IDShieldFamily2021: 'IDShield Family 2021',
  IDShieldIndividual2021: 'IDShield Individual 2021',
  LegalCDLP: 'Legal CDLP',
  LegalPlanFamily: 'Legal Plan Family',
  LegalPlanMemberSpouse2024: 'Legal Plan Member Spouse 2024',
  LegalShield: 'LegalShield',
  LegalSmallBusiness: 'Legal Small Business',
  LegalSuperCDLP: 'Legal Super CDLP',
} as const;

const PRODUCT_GROUPS: Record<string, string[]> = {
  legal: [PRODUCT_TYPE.LegalShield, PRODUCT_TYPE.LegalPlanFamily, PRODUCT_TYPE.LegalPlanMemberSpouse2024],
};

export const useUpgradeableSubscriptions = () => {
  const { data: subscriptions } = useGetSubscriptionsByIdentityId();
  const { data: profileData, isLoading: profileIsPending } = useGetProfile();

  return useQuery<UpgradeableSubscription[]>({
    enabled: !profileIsPending && !!subscriptions?.length,
    queryFn: async () => {
      const locale = profileData?.locale || 'en-US';

      const offersCache: Record<string, Offer[]> = {};

      const upgrades: UpgradeableSubscription[] = [];
      for (const subscription of subscriptions) {
        if (
          !subscription.administrativeArea ||
          ![Subscriptions.SubscriptionStatus.ACTIVE, Subscriptions.SubscriptionStatus.CATCHING_UP].includes(
            subscription.status,
          )
        ) {
          continue;
        }

        // filter groups
        if (subscription.source?.channel !== 'network' || subscription.source?.groupId) {
          continue;
        }

        const offersKey = `${locale}_${subscription.administrativeArea}`;
        if (!offersCache[offersKey]) {
          offersCache[offersKey] = await getOffers(locale, subscription.administrativeArea as CAProvTerr | USStates);
        }
        const offers = offersCache[offersKey];

        // exclude any billing periods other than monthly for now
        if (subscription.billingFrequency?.period !== Subscriptions.BillingPeriod.MONTHLY) {
          continue;
        }

        let availableMerchantizedTiers: AvailableMerchandizedTier[] = [];

        for (const offerItem of subscription.offer.offerItems || []) {
          if (!offerItem.productDetail?.productType) {
            continue;
          }
          const productType = offerItem.productDetail.productType;
          const productGroup = Object.keys(PRODUCT_GROUPS).find((group) =>
            PRODUCT_GROUPS[group]?.includes(productType),
          );

          if (!productGroup) {
            continue;
          }

          for (const offer of offers) {
            // an offer may include more than one product matching our filter (gbb does)
            const products = offer.products?.filter((p) => {
              const merchandizedProductGroup = Object.keys(PRODUCT_GROUPS).find((group) =>
                PRODUCT_GROUPS[group]?.includes(p.productType || ''),
              );
              return merchandizedProductGroup === productGroup;
            });

            for (const product of products || []) {
              const merchandizedTiers = product.merchandizedTiers?.filter((tier) => {
                return tier.prices?.some((price) => {
                  return price.billingPeriod === subscription.billingFrequency.period;
                });
              });

              for (const tier of merchandizedTiers || []) {
                const price = tier.prices?.find(
                  (price) => price.billingPeriod === subscription.billingFrequency.period,
                );
                if (!price) {
                  continue;
                }

                const availableForSale = !subscription.offer.offerItems?.some(
                  (item) =>
                    item.productDetail?.productType === productType && item.productDetail.productTier === tier.name,
                );

                availableMerchantizedTiers.push({
                  availableForSale,
                  merchandizedProduct: product,
                  offer,
                  price,
                  tier: tier.name || '',
                });
              }
            }
          }
        }

        availableMerchantizedTiers = availableMerchantizedTiers
          .filter((tier) => {
            const hasExperimentalTier = availableMerchantizedTiers.some(
              (t) => t.offer?.type === Offers.OfferType.EXPERIMENTAL,
            );
            return !hasExperimentalTier || tier.offer?.type === Offers.OfferType.EXPERIMENTAL;
          })
          .sort((a, b) => {
            // sort by renewalListPrice asc
            return (a.price?.renewalListPrice || 0) - (b.price?.renewalListPrice || 0);
          });

        // i can only upgrade for now
        const myOfferItem = subscription.offer.offerItems?.find(
          (item) => item.productDetail?.productType === availableMerchantizedTiers[0]?.merchandizedProduct?.productType,
        );
        const myTier = myOfferItem?.productDetail?.productTier;
        const myTierIndex = availableMerchantizedTiers.findIndex((tier) => tier.tier === myTier);
        if (myTierIndex > -1) {
          // we know what tier we have, we can disable all tiers below it
          for (let i = 0; i < myTierIndex; i++) {
            availableMerchantizedTiers[i].availableForSale = false;
          }
        } else {
          // for now we can only upgrade, so disable the first tier
          if (availableMerchantizedTiers.length > 0) availableMerchantizedTiers[0].availableForSale = false;
        }

        const availableForSaleTiers = availableMerchantizedTiers.filter((tier) => tier.availableForSale);

        if (availableForSaleTiers.length > 0) {
          upgrades.push({
            availableMerchandizedTiers: availableMerchantizedTiers,
            subscription,
          });
        }
      }

      Object.assign(window, { _upgrades: upgrades });

      return upgrades;
    },
    queryKey: ['upgradeable-subscriptions', profileData?.locale, subscriptions],
    staleTime: 1000 * 60 * 5,
  });
};
