import React, { FC } from 'react';
import { Container, Modal, Button, Text, Image, ModalProps, Heading, Spinner } from '@legalshield/adonis-ux-framework';

import './ConfirmUpgradeModal.scss';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import errorIcon from './errorIcon.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import successIcon from './successIcon.svg';

export type ModalStateType = 'confirm' | 'success' | 'error';
interface ConfirmUpgradeModalProps extends ModalProps {
  modalState: ModalStateType;
  title: string;
  plan?: string;
  monthlyPrice: string;
  memberNumber: string;
  loadingTitle: string;
  isLoading: boolean;
  onCancelPress: () => void;
  onConfirmPress: () => void;
  onDonePress: () => void;
  onErrorOkPress: () => void;
  tier: string;
  nextBillOn: string;
}

const ConfirmUpgradeModal: FC<ConfirmUpgradeModalProps> = (
  {
    modalState = 'confirm',
    title,
    plan,
    monthlyPrice,
    memberNumber,
    loadingTitle,
    isLoading,
    onCancelPress,
    onConfirmPress,
    onDonePress,
    onErrorOkPress,
    tier,
    nextBillOn,
  },
  ...props
) => {
  const handleCancel = () => {
    if (!isLoading) {
      onCancelPress();
    }
  };

  const showCloseButton = !isLoading && modalState !== 'success';

  return (
    <Modal
      closeButton={showCloseButton}
      closeFunction={handleCancel}
      position="top"
      contentProps={{
        onEscapeKeyDown: showCloseButton ? onCancelPress : (e) => e.preventDefault(),
        onInteractOutside: showCloseButton ? onCancelPress : (e) => e.preventDefault(),
      }}
      classNames={['confirm-upgrade-modal']}
      {...props}
    >
      {modalState === 'error' && (
        <>
          <Image src={errorIcon} alt="errorIcon" height={32} width={32} />
          <Container background="none" flexbox flexDirection={'column'} alignItems={'center'}>
            <Heading
              as="T20"
              text={string_table.UPGRADE_CONFIRMATION_ERROR_TEXT}
              classNames={['confirm-upgrade-modal__title', 'mt-4']}
            />
            <Text
              text={string_table.UPGRADE_CONFIRMATION_MODAL_ERROR_TEXT}
              textSize="large"
              classNames={['confirm-upgrade-modal__text', 'mb-4', 'mt-4']}
            />
          </Container>
          <Modal.Actions>
            <Button variant="secondary" label={string_table.DONE} textSize="medium" onClick={onErrorOkPress} />
          </Modal.Actions>
        </>
      )}
      {modalState === 'success' && (
        <>
          <Image src={successIcon} alt="successIcon" height={32} width={32} />
          <Container background="none" flexbox flexDirection={'column'} alignItems={'center'}>
            <Heading
              as="T20"
              text={string_table.UPGRADE_CONFIRMATION_SUCCESS_TEXT}
              classNames={['confirm-upgrade-modal__title', 'mt-4']}
            />
            <Text
              text="Your receipt may take up to 72 hours to process." // TODO: Update this copy
              textSize="large"
              classNames={['confirm-upgrade-modal__text', 'mb-4', 'mt-4']}
            />
          </Container>
          <Modal.Actions>
            <Button variant="secondary" label={string_table.DONE} textSize="medium" onClick={onDonePress} />
          </Modal.Actions>
        </>
      )}
      {modalState === 'confirm' && (
        <>
          {isLoading ? (
            <>
              <Modal.Title>{loadingTitle}</Modal.Title>
              <Container flexbox flexDirection="column" alignItems="center">
                <Spinner spinnerSize="large" />
              </Container>
            </>
          ) : (
            <>
              <Modal.Title>{title}</Modal.Title>
              <Container flexbox flexDirection="column">
                <Text textSize="large" text={`You're about to upgrade your membership to ${tier}.`} />
                <Container flexbox flexDirection="row" classNames={['mt-4']}>
                  <Text textSize="large" text="Plan:" textWeight="semibold" classNames={['mr-2']} />
                  <Text textSize="large" text={plan} />
                </Container>
                <Container flexbox flexDirection="row">
                  <Text textSize="large" text="Monthly Price:" textWeight="semibold" classNames={['mr-2']} />
                  <Text textSize="large" text={`$${monthlyPrice}/mo`} />
                </Container>
                <Container flexbox flexDirection="row">
                  <Text textSize="large" text="Next Bill Date:" textWeight="semibold" classNames={['mr-2']} />
                  <Text textSize="large" text={`${nextBillOn}`} />
                </Container>
                <Container flexbox flexDirection="row">
                  <Text textSize="large" text="Member Number:" textWeight="semibold" classNames={['mr-2']} />
                  <Text textSize="large" text={memberNumber} />
                </Container>
              </Container>
            </>
          )}
          <Modal.Actions>
            <Button
              variant="primary"
              label={string_table.UPGRADE_CONFIRMATION_CTA_TEXT}
              textSize="medium"
              disabled={isLoading}
              onClick={onConfirmPress}
            />
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
};

export default ConfirmUpgradeModal;
